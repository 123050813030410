import api from './api'
import type { ResponseResult } from '~/types/axios'

async function Middleware(req: (body: any) => Promise<ResponseResult<any>>, params: any, original = false) {
  const res = await req(params)
  if (original) {
    return res
  } else {
    return res.data
  }
}

export const service = {
  getSelectUser: (params: any) => Middleware(api.getSelectUser, params),
  totalStatistics: (params: any) => Middleware(api.totalStatistics, params),
  pageList: (params: any) => Middleware(api.pageList, params),
}
