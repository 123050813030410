import { request } from '@/utils'

export default {
  getProcessStatus: (projectId: string) => request.get(`/project/implemente/node/getNodeCompletedInfoByProjectId/${projectId}`),
  getEmptyCustomer: (refId: string) => {
    return {
      refId,
      customerName: null,
      mobile: null,
    }
  },
  saveCustomer: (data: Recordable) => request.post('/project/customer/profile/saveWithSysOut', data),
  getCustomerList: (projectId: string) => request.get(`/project/customer/profile/findAllWithSysOut/${projectId}`),
  getFirstBidDate: (projectId: string) => request.get(`/project/implemente/node/getFirstBidDate/${projectId}`),
  // 获取项目启动会
  getStarterProcessInfo: (projectId: string) => request.get(`/project/implemente/node/getKickoff/${projectId}`),
  saveStarterProcessInfo: (data: Recordable) => request.post('/project/implemente/node/saveOrUpdateKickoff', data),
  getEmptyStarterProcess: () => {
    return {
      supervisionType: null,
      kickoffPoint: null,
      customerIds: null,
      customerInfos: null,
      implementationUserIds: null,
      workerInfos: null,
      externalCoordination: null,
      preInnerCheckDate: null,
      preFinalCheckDate: null,
    }
  },
  // 获取项目现场勘察
  getSiteSurveyProcessInfo: (projectId: string) => request.get(`/project/implemente/node/getExplorationLast/${projectId}`),
  getSiteSurveyProcessList: (data: any) => request.post(`/project/implemente/node/explorationPageList`, data.body),
  saveSiteSurveyProcessInfo: (data: Recordable) => request.post('/project/implemente/node/saveOrUpdateExploration', data),
  getEmptySiteSurveyProcess: () => {
    return {
      explorationTime: null,
      customerIds: null,
      customerInfos: null,
      explorationPoint: null,
      createByName: null,
    }
  },
  // 获取项目深化设计
  getDetailedDesignProcessInfo: (projectId: string) => request.get(`/project/implemente/node/getDeepDesign/${projectId}`),
  saveDetailedDesignProcessInfo: (data: Recordable) => request.post('/project/implemente/node/saveOrUpdateDeepDesign', data),
  getEmptyDetailedDesignProcess: () => {
    return {
      deviceArriveTime: null,
      materialsArriveTime: null,
      preInnerCheckDate: null,
      preFinalCheckDate: null,
      remark: null,
    }
  },
  // 获取项目外包\厂商
  getThirdPartySupportProcessInfo: (projectId: string) => request.get(`/project/implemente/node/getOutsource/${projectId}`),
  saveThirdPartySupportProcessInfo: (data: Recordable) => request.post('/project/implemente/node/saveOrUpdateOutsource', data),
  getEmptyThirdPartySupportProcess: () => {
    return {
      listData: [],
    }
  },
  // 获取项目进场报验
  getIncomingInspectionProcessInfo: (projectId: string) => request.get(`/project/implemente/node/getInspection/${projectId}`),
  saveIncomingInspectionProcessInfo: (data: Recordable) => request.post('/project/implemente/node/saveOrUpdateInspection', data),
  getEmptyIncomingInspectionProcess: () => {
    return {
      enterDate: null,
      inpsectionTime: null,
      managementFee: null,
      inspectionRemark: null,
    }
  },
  // 获取项目货品验收
  getGoodsAcceptanceProcessInfo: (projectId: string) => request.get(`/project/implemente/node/getGoodsValidation/${projectId}`),
  saveGoodsAcceptanceProcessInfo: (data: Recordable) => request.post('/project/implemente/node/saveOrUpdateGoodsValidation', data),
  getGoodsAcceptanceProcessMaterialArrivalTime: (projectId: string) =>
    request.get(`/project/implemente/node/getAuxiliaryReceiveDate/${projectId}`),
  getGoodsAcceptanceProcessDeviceArrivalTime: (projectId: string) =>
    request.get(`/project/implemente/node/getDeviceReceiveDate/${projectId}`),
  getEmptyGoodsAcceptanceProcess: () => {
    return {
      marchintoTime: null,
      materialsArrivalSituation: null,
      devicesArrivalSituation: null,
      devicesReceivedTime: null,
    }
  },
  // 获取项目现场实施
  getSiteImplementProcessInfo: (projectId: string) => request.get(`/project/implemente/node/getSceneConstruction/${projectId}`),
  saveSiteImplementProcessInfo: (data: Recordable) => request.post('/project/implemente/node/saveOrUpdateSceneConstruction', data),
  getEmptySiteImplementProcess: () => {
    return {
      wiringProgress: null,
      remark: null,
      qualityInspectionResult: 2,
      qualityInspectionDate: null,
      qualityInspectionUser: null,
      explorationBeginTime: null,
      explorationEndTime: null,
      process: 0,
    }
  },
  // 确认外包用工接收
  // confirmReceiveLabor: (projectId: string) => request.get(`/project/implemente/node/receiveOutSourcing/${projectId}`),
  // 获取外包用工信息
  getReceiveLabor: (projectId: string) => request.get(`/project/implemente/node/getOutsource/${projectId}`),
  // 获取项目安装调试
  getInstallDebugProcessInfo: (projectId: string) => request.get(`/project/implemente/node/getDeviceDebug/${projectId}`),
  saveInstallDebugProcessInfo: (data: Recordable) => request.post('/project/implemente/node/saveOrUpdateDeviceDebug', data),
  getEmptyInstallDebugProcess: () => {
    return {
      debugTime: null,
      debugProcess: null,
      remark: null,
      qualityInspectionResult: 2,
      qualityInspectionDate: null,
      qualityInspectionUser: null,
      debugEndTime: null,
      debugBeginTime: null,
      process: 0,
    }
  },
  // 获取项目软件实施
  getSoftImplementProcessInfo: (projectId: string) => request.get(`/project/implemente/node/getSoft/${projectId}`),
  saveSoftImplementProcessInfo: (data: Recordable) => request.post('/project/implemente/node/saveOrUpdateSoft', data),
  getEmptySoftImplementProcess: () => {
    return {
      processState: null,
      processStateTime: null,
      softProcess: null,
      remark: null,
      qualityInspectionResult: 2,
      qualityInspectionDate: null,
      qualityInspectionUser: null,
      softBeginTime: null,
      softEndTime: null,
      process: 0,
    }
  },
  getSoftIncomingHistory: (projectId: string) => request.get(`/project/implemente/node/getSoftHistory/${projectId}`),
  // 获取项目变更
  getProjectChangeProcessInfo: (projectId: string) => request.get(`/project/implemente/node/getChange/${projectId}`),
  saveProjectChangeProcessInfo: (data: Recordable) => request.post('/project/implemente/node/saveOrUpdateChange', data),
  getEmptyProjectChangeProcess: () => {
    return {
      changeContent: null,
    }
  },
  // 获取项目延期
  getDelayProcessInfo: (projectId: string) => request.get(`/project/implemente/node/getDelayed?projectId=${projectId}`),
  saveDelayProcessInfo: (data: Recordable) => request.post('/project/implemente/node/saveOrUpdateDelayed', data),
  getDelayedList: (data: any) => request.post('/project/implemente/node/getDelayedList', data.body),
  getEmptyDelayProcess: () => {
    return {
      completedTime: null,
      delayedRemark: null,
    }
  },
  getStopProcessInfo: (projectId: string) => request.get(`/project/implemente/node/getStopWork?projectId=${projectId}`),
  saveStopProcessInfo: (data: Recordable) => request.post('/project/implemente/node/saveOrUpdateStopWork', data),
  getStopWorkList: (data: any) => request.post('/project/implemente/node/getStopWorkList', data.body),
  getReworkProcessInfo: (projectId: string) => request.get(`/project/implemente/node/getRework?projectId=${projectId}`),
  saveReworkProcessInfo: (data: Recordable) => request.post('/project/implemente/node/saveOrUpdateRework', data),
  getReworkList: (data: any) => request.post('/project/implemente/node/getReworkList', data.body),
  // 获取项目质检、内验
  getQualityAcceptanceProcessInfo: (projectId: string) => request.get(`/project/implemente/node/getQualityInspection/${projectId}`),
  saveQualityAcceptanceProcessInfo: (data: Recordable) => request.post('/project/implemente/node/saveOrUpdateQualityInspection', data),
  applyQualityAcceptance: (projectId: string) =>
    request.post(`/project/sys/project/infoInternalInspection/applyInternalInspection/${projectId}`),
  getApplyQualityAcceptanceHistory: (projectId: string) =>
    request.get(`/project/sys/project/infoInternalInspection/searchHistoryApplyInternalInspection?projectId=${projectId}`),
  getEmptyQualityAcceptanceProcess: () => {
    return {
      completedTime: null,
      internalInspectionTime: null,
      internalInspectionResult: 2,
      concealedProjectDate: null,
      concealedProjectResult: 2,
      deviceDebugDate: null,
      deviceDebugResult: 2,
      softDate: null,
      softResult: 2,
    }
  },
  getProcessLogsByDateRange: (projectId: string, startTime: string, endTime: string) =>
    request.post(`/project/implemente/log/getCalendarOfLog/${projectId}`, {
      startTime,
      endTime,
    }),
  getConstructionScheduleInfo: (data: Recordable) => request.post('/project/implemente/log/getArrangementLogBySubmitDate', data),
  saveConstructionSchedule: (projectId: string, data: Recordable) =>
    request.post(`/project/implemente/log/saveOrUpdateArrangementLog/${projectId}`, data),
  getEmptyConstructionSchedule: (logTime?: string) => {
    return {
      logTime,
      submitTime: null,
      content: null,
      attachment: [],
    }
  },
  getConstructionMeetingInfo: (data: Recordable) => request.post('/project/implemente/log/getRegularmeetingLogBySubmitDate', data),
  saveConstructionMeeting: (projectId: string, data: Recordable) =>
    request.post(`/project/implemente/log/saveOrUpdateRegularmeetingLog/${projectId}`, data),
  getEmptyConstructionMeeting: (logTime?: string) => {
    return {
      logTime,
      submitTime: null,
      content: null,
      attachment: [],
    }
  },
  getConstructionLogs: (projectId: string, submitDate: string | number) =>
    request.post(`/project/implemente/log/getWeekOfAllUserWorkLog/${projectId}`, {
      submitDate,
    }),
  getConstructionWeeklyByDate: (projectId: string, data: Recordable) =>
    request.post(`/project/implemente/log/weekLogGetDetail/${projectId}`, data),
  saveConstructionWeekly: (data: Recordable) => request.post('/project/implemente/log/saveOrUpdateWeekLog', data),
  getEmptyConstructionWeekly: () => {
    return {
      submitTime: null,
      startTime: null,
      endTime: null,
      completedWork: null,
      problemRemark: null,
      needCoordinate: null,
      expenseFee: null,
      attachment: [],
    }
  },
  // 现场实施质检申请
  initiateApplication_scene: (projectId: string, forceApply = false) =>
    request.get(`/project/implemente/node/applySceneConstructionQualityInspection/${projectId}/${forceApply}`),
  // 软件实施质检申请
  initiateApplication_software: (projectId: string, forceApply = false) =>
    request.get(`/project/implemente/node/applySoftQualityInspection/${projectId}/${forceApply}`),
  // 设备调试质检申请
  initiateApplication_equipment: (projectId: string, forceApply = false) =>
    request.get(`/project/implemente/node/applyDeviceDebugQualityInspection/${projectId}/${forceApply}`),

  // 项目实施-收货详情-提货申请查询
  getPageListReceivingDetails: (data: Recordable) => request.post('/purchase/purchase/category/applyV2/pageListReceivingDetails', data),
  // 项目实施-收货详情-项目经理接收详情分页查询
  getArrangeDetailToProjectDetailPage: (data: Recordable, applyId: string) =>
    request.post(`/warehouse/receiveGoods/getArrangeDetailToProjectDetailPage?applyId=${applyId}`, data),
  // 项目经理接收详情分页查询查询货品开箱图片
  getArrangeDetailToProjectDetailImage: (id: string) =>
    request.get(`/warehouse/receiveGoods/getArrangeDetailToProjectDetailImage?id=${id}`),

  getCategory: () => request.get('/purchase/purchase/supply/service/type/categoryCascadeQuery'),
  getBrand: () => request.get('/purchase/purchase/supply/service/type/brandCascadeQuery'),

  // 查询流程进度历史记录
  pageListProcess: (data: any) => request.post('/project/implemente/node/pageListProcess', data.body),

  getOutSourcePage: (params: PageParams) => {
    return request.post(`/project/outsource/list?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {})
  },
  saveOutSourceItem: (data: Recordable) => request.post('/project/outsource/save', data),
}
