import { request } from '@/utils'

export default {
  giftPageList: (params: any) => request.post('/purchase/purchase/materials/giftPageList', params.body),
  suppliesPageList: (params: any) => request.post('/purchase/purchase/materials/suppliesPageList', params.body),
  getDetail: (id: string) => request.get(`/purchase/purchase/materials/getDetail/${id}`),
  getAllBrand: () => request.get('/purchase/purchase/materials/getAllBrand'),
  getAllType: () => request.get('/purchase/purchase/materials/getAllType'),
  saveMaterials: (data: any) => request.put('/purchase/purchase/materials/saveMaterials', data),
  materialsEnquiry: (data: any) => request.post('/purchase/purchase/materials/materialsEnquiry', data),
  deleteMaterialsPrice: (id: any) => request.delete(`/purchase/purchase/materials/deleteMaterialsPrice/${id}`),
  getMaterialsPriceDetail: (id: string) => request.get(`/purchase/purchase/materials/getMaterialsPriceDetail/${id}`),
  saveMaterialsPrice: (data: any) => request.put('/purchase/purchase/materials/saveMaterialsPrice', data),

  getPartnerAll: () => request.get('/purchase/purchase/partner/findAll'),
  getTransportationExpenses: () => request.get('/system/diction/findByCode/purchase_category_transportation_expenses'),
}
